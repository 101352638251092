$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #353353;
$coloryellow: #ffd15c;
$colorpink: #ff4c60;
$colorblue: #6c6ce5;
$colordefault: #454360;

/*Koodama colors*/
$kmd_bg: #fcfcfc;
$kdm_main: #47cfbf;
$kdm_aux: #4cd8b7;
$kdm_aux2: #5cec8c;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
  -moz-transition: all $second ease-in-out;
  transition: all $second ease-in-out;
}
